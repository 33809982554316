@use "abstracts" as a;

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: a.$colorBlack;
  font-size: a.rem(18px);
  line-height: 1.46;
  @include a.hyphenate();
}

strong {
  font-weight: 700;
}

h1,
h2 {
  font-family: "Hind", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: a.rem(44px);
  line-height: 1;
  margin-bottom: 2px;
}

h2 {
  font-weight: 500;
  font-size: a.rem(24px);
  line-height: 1.2;
  margin-bottom: 40px;
}

h3 {
  font-weight: 700;
  margin-bottom: 3px;
}

a {
  color: a.$colorBlack;
  color: a.$colorAccent;
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: a.$colorAccent;
    color: a.$colorBlack;
    text-decoration: underline;
  }
}

p,
ul {
  margin-bottom: 16px;
}

p + ul {
  margin-top: -10px;
}

.content {
  ul {
    list-style: initial;
    padding: 0 0 0 20px;

    li {
      line-height: 1.6;
    }
  }
}

@media only screen and (min-width: 420px) {
  h1 {
    font-size: a.rem(56px);
  }

  h2 {
    font-size: a.rem(28px);
  }
}
