@use "abstracts" as a;

.langswitch {
  position: relative;
  ul {
    font-size: a.rem(16px);
    display: block;
    text-align: right;
    color: #999999;
  }

  li {
    display: inline-block;
  }

  a {
    color: #999999;
    &:hover {
      color: #000000;
    }
  }

  .lang-current {
    color: #000000;
  }
}
