@use "abstracts" as a;

body {
  background-color: a.$colorWhite;
}

.inner-wrap {
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.langswitch {
  padding-top: 20px;
  padding-bottom: 20px;
  // outline: 2px solid green;
}

.content {
  padding-top: 100px;
  padding-bottom: 40px;
  // outline: 2px solid red;
}

.module {
  margin-bottom: 40px;
}
